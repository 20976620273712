exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-formacion-jsx": () => import("./../../../src/pages/formacion.jsx" /* webpackChunkName: "component---src-pages-formacion-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-medios-jsx": () => import("./../../../src/pages/medios.jsx" /* webpackChunkName: "component---src-pages-medios-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-servicios-[id]-jsx": () => import("./../../../src/pages/servicios/[id].jsx" /* webpackChunkName: "component---src-pages-servicios-[id]-jsx" */),
  "component---src-pages-servicios-jsx": () => import("./../../../src/pages/servicios.jsx" /* webpackChunkName: "component---src-pages-servicios-jsx" */)
}

